
.background{
    background-color: rgb(37, 37, 37);
    height:100%;
}
.resume {
    max-width:80%;
    height: auto;
}

.phoneCard {
    max-width:75% !important;
}
.phoneCardRow{
    justify-content:center;
}
.offcanvasModal {
    max-width:50% !important;
    background-color:rgb(175, 175, 175) !important;
    font-family: 'PT Mono', swap;
    font-weight: bold;
}

.link {
    color:black;
    font-weight: lighter !important;
    margin-left: 7%;
}

.offHeader {
    font-weight: bold !important;
}

.phoneBrand {
    color:white !important; 
}
