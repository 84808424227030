a:link {
    text-decoration: none;
}
a:visited {
    text-decoration: none;
}
a:hover{
    text-decoration: none;
}
a:active{
    text-decoration: none;
}

.fontHeader {
    font-family: 'PT Mono', swap;
    font-weight: bold;
    color:rgb(175, 175, 175);
}

.fontFooter {
    font-family: 'PT Mono', swap;
    text-align:center;
    color:rgb(175, 175, 175);
}

h1 {
    text-align:center;
    padding: 2%;
    font-weight: bold;
}

.collage {
    border:solid rgb(19, 19, 19);;
    margin:2%;
    
}
.collageHead {
    margin-left:5%;
    font-size:160%;
    color:rgb(175, 175, 175);
}
.background {
    background-color: rgb(37, 37, 37);
}
.resume {
    border: solid black;
    margin:2%;
}
.resumeContainer{
    text-align:center;
}
.card {
    margin: 5%;
    margin-left:20%;
    margin-right:20%;
    padding-top:1%;
    padding-bottom:1%;
    background-color:#0d6efd;
    color:#0d6efd;
}
.bottom {
    margin-left:30%;
    margin-right:30%;
}
.fullview {
    height:100vh !important;
    width:100vw;
}

.notepad {
    width:100vw;
    height:95vh;
    background-color:white;
    z-index:900;
}

.pixel {
    width:5px;
    height:5px;
    background-color:purple;
    position:absolute;
    border-radius:90%;
    pointer-events: none;
    z-index:1000;
}

.pixel.description {
    pointer-events:none;
}

.resetButton {
    margin:1%;
    z-index:2600;
    position:relative;
}

.radioButtons {
    z-index:2800;
    margin:1%;
    align-self: center;
}

.optionsWrapper {
    display:flex;
}
.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}