
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 4s linear;
  }
}

.App-header {
  width:100%;
  background-color:slateblue;
  display:flex;
  justify-content:space-between;
  max-height:8vh;
  align-items:center;
}

.App-header-mobile {
  width:100%;
  background-color:slateblue;
  display:flex;
  justify-content:space-between;
  max-height:5vh;
  align-items:center;
}

.Left-name {
  display:flex;
  flex-direction: row;
}

.App-logo {
  max-height: 6vh;
}

.Routing-links {
  text-align:right;
  display:flex;
  margin-right:4%;
  align-items:right;
}

.Routing-links p{
  margin-left: 0.3vw;
  
}

.App-link {
  color: #61dafb;
}

.Home-icon {
  font-size:150%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Link-icon {
  text-decoration: none;
  color:black;
  opacity:0.7
}

.Link-icon:hover{
  color:black;
  opacity:0.95;
  transition:0.5s;
}



